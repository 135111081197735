import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../slices/authSlice';
import { resetUserState } from '../slices/userSlice'; // Import resetUserState to clear user data
import { auth } from '../firebaseConfig';
import { persistor } from '../store';
import { Link } from 'react-router-dom';

function Header() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Check if user is logged in from auth slice
  const { firstName, lastName } = useSelector((state) => state.user); // Select first and last name from user slice

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        dispatch(clearUser()); // Clear auth state
        dispatch(resetUserState()); // Clear user data
        persistor.purge(); // Clears all persisted data
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <>
      <div className="header-full-width-container">
        <header className="content-container header">
          <Link to="/">
            <img src="images/logo-dark.png" alt="Sparkle" className="header-logo" />
          </Link>
          {isLoggedIn && (
            <div className="header-user-status">
              <span>{`${firstName} ${lastName}`}</span> {/* Display first and last name */}
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </header>
      </div>

      {/* Phone Banner Below Header */}
      <div className="content-container column">
        <div className="top-section-contact-banner">
          <span>Give us a call at <a href="tel:8645205018">864-520-5018</a></span>
        </div>
      </div>
    </>
  );
}

export default Header;






// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { clearUser } from '../slices/authSlice';
// import { auth } from '../firebaseConfig';
// import { Link } from 'react-router-dom';

// function Header() {
//   const dispatch = useDispatch();
//   const isLoggedIn = useSelector((state) => state.auth.user);

//   const handleLogout = () => {
//     auth.signOut()
//       .then(() => {
//         dispatch(clearUser()); // Clear user in Redux
//       })
//       .catch((error) => {
//         console.error('Error signing out: ', error);
//       });
//   };

//   return (
//     <>
//       <div className="header-full-width-container">
//         <header className="content-container header">
//           <Link to="/">
//             <img src="images/logo-dark.png" alt="Sparkle" className="header-logo" />
//           </Link>
//           {isLoggedIn && (
//             <div className="header-user-status">
//               <span>{user.email}</span>
//               <button onClick={handleLogout}>Logout</button>
//             </div>
//           )}
//         </header>
//       </div>

//       {/* Phone Banner Below Header */}
//       <div className="content-container column">
//         <div className="top-section-contact-banner">
//           <span>Give us a call at <a href="tel:8645205018">864-520-5018</a></span>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Header;



