import React from 'react';
import './CustomButton.css'; // Import the new styles for this button

const CustomButton = ({ color, text, onClick, disabled = false, isLoading, loadingText }) => {
  return (
    <button
      className="custom-button"
      style={{ backgroundColor: color }}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? loadingText : text}
    </button>
  );
};

export default CustomButton;
