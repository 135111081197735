// src/screens/PropertyScreen.js
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';



import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';

import Checkbox from '../components/Checkbox';
import NumberIncrementor from '../components/NumberIncrementor';
import GoogleMapComponent from '../components/GoogleMapComponent';
import Header from '../components/Header';
import ErrorBox from '../components/ErrorBox';
import ConfirmationDialog from '../components/ConfirmationDialog';

import { updateJob } from '../slices/jobSlice';
import { updateProperty, updateOrResetProperty } from '../slices/propertySlice';
import { updateProperties } from '../slices/propertiesSlice';
import { setAssignedPropertyIds } from '../slices/assignedPropertiesSlice';

import { resetRecurringDay } from '../slices/desiredRecurringDaySlice';
import { resetFrequency } from '../slices/serviceFrequencySlice';
import { resetCleaners } from '../slices/desiredCleanersSlice';
import { resetAvailableRecurringDays } from '../slices/availableRecurringDaysSlice';
import { resetRecurringSoloDayCleaners } from '../slices/recurringSoloDayCleanersSlice';
import { resetRecurringMultiDayCleaners } from '../slices/recurringMultiDayCleanersSlice';
import { resetDate } from '../slices/desiredDateSlice';

import { getFirebaseToken } from '../utils/firebaseTokenUtil';



const PropertyScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const inputRef = useRef(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);


  //unused
  const [loadingText, setLoadingText] = useState("");

  // page form errors
  const [formErrors, setFormErrors] = useState([]); 



  // new / save / delete 
  const [needsSave, setNeedsSave] = useState(false);



  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  
  const [isProcessing, setIsProcessing] = useState(false);



  // State for input, suggestions, and error handling // address lookup 
  const [propertyLookupErrors, setPropertyLookupErrors] = useState([]);
  const [propertyLoadErrors, setPropertyLoadErrors] = useState([]);
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);


  // State for dropdown and selected property
  const [selectedItem, setSelectedItem] = useState(null);
  const [dropdownItems, setDropdownItems] = useState([]);

  const jobId = useSelector((state) => state.job.id);

  console.log("jobId", jobId);

  const propertiesState = useSelector((state) => state.properties);
  const cleaningType = useSelector((state) => state.job.cleaningType);

  const assignedPropertyIds = useSelector((state) => state.assignedProperties.assignedPropertyIds);

  const propertyId = useSelector((state) => state.property.id);
  const propertyName = useSelector((state) => state.property.propertyName);
  const defaultProperty = useSelector((state) => state.property.defaultProperty);
  const propertyType = useSelector((state) => state.property.propertyType);
  const apartmentUnit = useSelector((state) => state.property.apartmentUnit);  
  const apartmentName = useSelector((state) => state.property.apartmentName);
  const address = useSelector((state) => state.property.address);
  const zipCode = useSelector((state) => state.property.zipCode);
  const city = useSelector((state) => state.property.city);  
  const meetOwnerAtDoorBinary = useSelector((state) => state.property.meetOwnerAtDoorBinary);
  const hiddenKeyBinary = useSelector((state) => state.property.hiddenKeyBinary);
  const unlockedBinary = useSelector((state) => state.property.unlockedBinary);
  const gateCommunityBinary = useSelector((state) => state.property.gateCommunityBinary);
  const numberBedrooms = useSelector((state) => state.property.numberBedrooms);
  const numberBathrooms = useSelector((state) => state.property.numberBathrooms);

  const numberHalfBathrooms = useSelector((state) => state.property.numberHalfBathrooms);
  const numberCeilingFans = useSelector((state) => state.property.numberCeilingFans);
  const nonMiniBlindsBinary = useSelector((state) => state.property.nonMiniBlindsBinary);

  const squareFeet = useSelector((state) => state.property.squareFeet);
  const propertyAccessNotes = useSelector((state) => state.property.propertyAccessNotes);
  const numberDogs = useSelector((state) => state.property.numberDogs);
  const numberCats = useSelector((state) => state.property.numberCats);
  const propertyPetsNotes = useSelector((state) => state.property.propertyPetsNotes);

  const latitude = useSelector((state) => state.property.latitude);
  const longitude = useSelector((state) => state.property.longitude);
  const geographicRegionId = useSelector((state) => state.property.geographicRegionId);
  const propertyState = useSelector((state) => state.property.propertyState);
  const formattedAddress = useSelector((state) => state.property.formattedAddress);
  const placeId = useSelector((state) => state.property.placeId);
  const supplyCenterDriveTime = useSelector((state) => state.property.supplyCenterDriveTime);
  const supplyCenterDriveMiles = useSelector((state) => state.property.supplyCenterDriveMiles);


  const property = useSelector((state) => state.property);

  console.log("property", property);





  // Update dropdown items when propertiesState or selectedItem changes
  useEffect(() => {
    const newDropdownItems = propertiesState.map(property => ({
      ...property,
      selected: selectedItem && property.id === selectedItem.id,
    }));
    setDropdownItems(newDropdownItems);
  }, [propertiesState, selectedItem]);


  // Set selected item based on propertyId or default property
  useEffect(() => {
    if (propertyId && propertiesState.length > 0) {
      const currentProperty = propertiesState.find(property => property.id === propertyId);
      if (currentProperty) {
        setSelectedItem(currentProperty);
      }
    }
  }, [propertyId, propertiesState]);



  // Handle dropdown selection change
  const handleSelectChange = async (event) => {
    const selectedPropertyId = Number(event.target.value);
    const selectedProperty = propertiesState.find(property => property.id === selectedPropertyId);
    
    setSelectedItem(selectedProperty);
    console.log("Selected property:", selectedProperty);

    // Call loadSelectedProperty to load the selected property details
    await loadSelectedProperty(selectedPropertyId);
  };



  const handleInputChange = async (text) => {
    setInputValue(text);
  
    if (!text.trim()) {
      setSuggestions([]);
      setPropertyLookupErrors([]); // Clear previous errors when input is cleared
      return;
    }
  
    try {
      const token = await getFirebaseToken();
      const response = await axios.post(
        `${apiBaseUrl}/property/get-address-suggestions`,
        { input: text },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (response.data.success) {
        setSuggestions(response.data.suggestions || []);
        setPropertyLookupErrors([]); // Clear errors on successful suggestion fetch
      } else {
        setPropertyLookupErrors(['Failed to fetch suggestions.']);
      }
    } catch (err) {
      console.error('Error during API call:', err);
      setPropertyLookupErrors(['Failed to call API. Please try again.']);
    }
  };
    

  const handleSuggestionClick = async (suggestion) => {
    setIsLoadingProperty(true);
    console.log("Suggestion:", suggestion);
  
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        setIsLoadingProperty(false);
        throw new Error('No Firebase ID token found in Redux store');
      }
  
      const response = await axios.post(
        `${apiBaseUrl}/property/get-place-details`,
        { placeId: suggestion.place_id, jobId },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );
  
      if (response.data.success) {
        const { addressDetails, location, geographicRegionId, supplyCenterDriveTime, supplyCenterDriveMiles } = response.data;
        const { address, city, state, zip, placeId, formattedAddress } = addressDetails;
        const { latitude, longitude } = location;
  
        dispatch(updateProperty({
          geographicRegionId,
          address,
          city,
          propertyState: state,
          zipCode: zip,
          placeId,
          formattedAddress,
          latitude,
          longitude,
          supplyCenterDriveTime,
          supplyCenterDriveMiles,
        })); 

  
        let propertyName = address; // Start with the address
        if (propertyType === 'apartment' && apartmentUnit && apartmentUnit.trim() !== '') {
          propertyName += `, unit ${apartmentUnit}`;
        }
  
        // Dispatch the updated propertyName
        dispatch(updateProperty({ propertyName }));
  
        setInputValue('');
        setSuggestions([]);
        setPropertyLookupErrors([]); // Clear errors on success
      } else {
        setPropertyLookupErrors(['Unexpected response status']);
      }
    } catch (error) {
      console.error('Error in handleSuggestionClick:', error.message);
      if (error.response && error.response.data && error.response.data.message) {
        let errorMessageFromServer = error.response.data.message;
        if (errorMessageFromServer === 'No service center in range') {
          errorMessageFromServer = `Property is out of range for service: ${suggestion.description}`;
        }
        setPropertyLookupErrors([errorMessageFromServer]);
      } else {
        setPropertyLookupErrors(['An unexpected error occurred.']);
      }
    } finally {
      setIsLoadingProperty(false);
    }
  };
  







  // Helper functions
  const isReadOnly = assignedPropertyIds.includes(propertyId);


    // Function to handle house selection
  const handleHouseSelection = () => {
    dispatch(updateProperty({ propertyType: 'house', apartmentName: '', apartmentUnit: '' }));
  };


  
  const loadSelectedProperty = async (propertyId) => {
    setIsProcessing(true);
    setLoadingText('Loading property...');
    setPropertyLoadErrors([]);  // Clear previous load errors

    const firebaseIdToken = await getFirebaseToken();
    if (!firebaseIdToken) {
      setIsProcessing(false);
      throw new Error('No Firebase ID token found in Redux store');
    }

    if (!jobId) {
      setIsProcessing(false);
      throw new Error('No JobId found in state');
    }

    try {
      const response = await axios.post(
        `${apiBaseUrl}/load-property-for-job`,
        { jobId, propertyId },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );

      if (response.status === 200) {
        const { property, savedProperties, assignedProperties } = response.data;

        dispatch(updateProperty(property));
        dispatch(updateProperties(savedProperties));
        dispatch(setAssignedPropertyIds(assignedProperties));
        dispatch(updateJob({ propertyId: property.id }));

        updatePrevValuesWithLoadedProperty(property);
        setNeedsSave(false);
        setPropertyLoadErrors([]);  // Clear load errors on success
      } else {
        setPropertyLoadErrors(['Unexpected response status']);
      }
    } catch (error) {
      setPropertyLoadErrors(['There was a problem loading the property. Please try again.']);
      console.error(error);
    }

    setIsProcessing(false);
  };



  // Event handlers
  const handleSaveChanges = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      await updatePropertyDatabase(false);
      alert("Property saved successfully!");
    } 
  };



  const confirmSaveBeforeNew = () => {
    if (needsSave) {
      setShowConfirmationDialog(true);
    } else {
      createNewProperty();
    }
  };
  
  const handleSaveBeforeNew = async () => {
    setShowConfirmationDialog(false); // Close dialog
    if (formErrors.length === 0) {
      await updatePropertyDatabase(false);
      await createNewProperty();
    }
  };
  
  const handleNoSaveAndNew = async () => {
    setShowConfirmationDialog(false); // Close dialog
    await createNewProperty();
  };



  


  // const handleNextPress = async () => {
  //   const isFormValid = validateForm();
  //   if (isFormValid) {
  //     setNextButtonLoading(true);
  //     await updatePropertyDatabase();
  //     setNextButtonLoading(false);
  //   }
  // };


  const handleNextPress = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      setNextButtonLoading(true);
      await updatePropertyDatabase();
      setNextButtonLoading(false);
      navigate('/todo-list'); 
    }
  };



  // Function to create a new property
  const createNewProperty = async () => {
    setIsProcessing(true);
    setLoadingText('Creating new property...');
    setPropertyLoadErrors([]);

    const firebaseIdToken = await getFirebaseToken();
    if (!firebaseIdToken) {
      setIsProcessing(false);
      throw new Error('No Firebase ID token found in Redux store');
    }

    if (!jobId) {
      setIsProcessing(false);
      throw new Error('No JobId found in state');
    }

    try {
      const response = await axios.post(
        `${apiBaseUrl}/customer-create-new-property`,
        { jobId },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );

      if (response.status === 200) {
        const { property, savedProperties, assignedProperties } = response.data;



        dispatch(updateOrResetProperty(property));
        dispatch(updateProperties(savedProperties));
        dispatch(setAssignedPropertyIds(assignedProperties));
        dispatch(updateJob({ propertyId: property.id }));

        updatePrevValuesWithLoadedProperty(property);
        setNeedsSave(false);
        setFormErrors([]);

        // Reset selected item to default selection
        setSelectedItem(null);  

      } else {
        setPropertyLoadErrors(['Unexpected response status']);
      }
    } catch (error) {
      setPropertyLoadErrors(['There was a problem creating the property. Please try again.']);
      console.error(error);
    }

    setIsProcessing(false);
  };



  const deleteProperty = async () => {
    setIsProcessing(true);
    setLoadingText('Deleting current property...');
    setPropertyLoadErrors([]);
  
    const firebaseIdToken = await getFirebaseToken();
    if (!firebaseIdToken) {
      setIsProcessing(false);
      throw new Error('No Firebase ID token found in Redux store');
    }
  
    try {
      const response = await axios.post(
        `${apiBaseUrl}/customer-delete-property`,
        { jobId, propertyId },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );
  
      if (response.status === 200) {
        const { property, savedProperties, assignedProperties } = response.data;

        dispatch(updateOrResetProperty(property));
        dispatch(updateProperties(savedProperties));
        dispatch(setAssignedPropertyIds(assignedProperties));
        dispatch(updateJob({ propertyId: property.id }));
  
        updatePrevValuesWithLoadedProperty(property);
        setNeedsSave(false);
        setFormErrors([]);

        // Reset selected item to default selection
        setSelectedItem(null);  

      } else {
        setPropertyLoadErrors(['Unexpected response status']);
      }
    } catch (error) {
      setPropertyLoadErrors(['There was a problem deleting the property. Please try again.']);
      console.error(error);
    }
  
    setIsProcessing(false);
  };

  
  const confirmDeleteProperty = () => {
    if (window.confirm("Are you sure you want to delete this property?")) {
      deleteProperty();
    }
  };
  

  const validateForm = () => {
    let newErrors = [];
  
    if (!propertyName) newErrors.push("A property save name must be chosen");
    if (!address) newErrors.push("An address must be entered");
    if (!numberBedrooms || numberBedrooms <= 0) newErrors.push("A number of bedrooms must be entered");
    if (!numberBathrooms || numberBathrooms <= 0) newErrors.push("A number of bathrooms must be entered");
    if (!squareFeet || squareFeet < 500) newErrors.push("Total square feet must contain a number greater than 500");
  
    // Check for apartment unit and name if property type is apartment
    if (propertyType === 'apartment') {
      if (!apartmentUnit.trim()) newErrors.push("Please enter the apartment unit");
      if (!apartmentName.trim()) newErrors.push("Please enter the apartment name");
    }
  
    setFormErrors(newErrors);
  
    if (newErrors.length > 0) {
      alert("Please address the errors before saving.");
    }
  
    return newErrors.length === 0;
  }; 

  
  const updatePropertyDatabase = async (navigateForward = true) => {
    setIsProcessing(true);
    setLoadingText('Saving property...');
  
    const data = {   
      propertyId,   
      jobId, // Include jobId in the data sent to the server
      propertyName,
      defaultProperty,
      propertyType,
      apartmentUnit,
      apartmentName,
      address,
      city,
      zipCode,
      numberBedrooms,
      numberBathrooms,
      numberHalfBathrooms,
      numberCeilingFans,
      nonMiniBlindsBinary,
      squareFeet,
      propertyAccessNotes,
      numberDogs,
      numberCats,
      propertyPetsNotes,
      meetOwnerAtDoorBinary: meetOwnerAtDoorBinary ? 1 : 0,
      hiddenKeyBinary: hiddenKeyBinary ? 1 : 0,
      unlockedBinary: unlockedBinary ? 1 : 0,
      gateCommunityBinary: gateCommunityBinary ? 1 : 0,
      latitude,                  
      longitude,                
      geographicRegionId,      
      propertyState,            
      formattedAddress,          
      placeId,                    
      supplyCenterDriveTime,
      supplyCenterDriveMiles
    };
  
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token found in Redux store');
      }
  
      const response = await axios.post(`${apiBaseUrl}/update-property`, data, {
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
      });
  
      if (response.status === 200) {

        dispatch(updateProperties(response.data.savedProperties));
        dispatch(updateProperty(response.data.property));
        dispatch(setAssignedPropertyIds(response.data.assignedProperties));

        if (response.data.updatedJob) {
          // New addition: Update the job in the Redux store only if updatedJob is not null/undefined
          dispatch(updateJob(response.data.updatedJob));
        }

        // Update prevValues to reflect the newly loaded property
        updatePrevValuesWithLoadedProperty(response.data.property);

        // Reset needsSave to false
        setNeedsSave(false);

        if (navigateForward) {
          // navigate('CustomTaskScreen');

          console.log("navigate to next page");
        }
        // Update prevValues to reflect the changes made
        updatePrevValuesWithLoadedProperty(data);
        // Reset needsSave to false as changes have been saved
        setNeedsSave(false);
      } else {
        // Handle error cases here
      }
    } catch (error) {
      console.error(error);
      // Handle error cases here
    } finally {
      setIsProcessing(false);
      setNextButtonLoading(false); // Reset here after processing is done
    }
  };  








  //state, useRef, change tracking, useEffect to track if a save is needed
  const initialState = {
    propertyName: null,
    propertyType: 'house',
    apartmentUnit: null,
    apartmentName: null,
    address: null,
    formattedAddress: null,
    zipCode: null,
    city: null,
    propertyState: null,       
    hiddenKeyPhotoId: null,
    numberBedrooms: null,
    numberBathrooms: null,
    numberHalfBathrooms: null,
    numberCeilingFans: null,
    nonMiniBlindsBinary: false,
    squareFeet: null,
    propertyAccessNotes: null,
    numberDogs: null,
    numberCats: null,
    propertyPetsNotes: null,
  }; 


  // Ref to keep track of previous values
  const prevValues = useRef({
    propertyName,
    defaultProperty,
    propertyType,
    apartmentUnit,
    apartmentName,
    address,
    city,
    propertyState,
    zipCode,
    numberBedrooms,
    numberBathrooms,
    numberHalfBathrooms,
    numberCeilingFans,
    nonMiniBlindsBinary,
    squareFeet,    
    meetOwnerAtDoorBinary,
    hiddenKeyBinary,    
    unlockedBinary,
    gateCommunityBinary,   
    propertyAccessNotes,
    numberDogs,
    numberCats,
    propertyPetsNotes, 
  });

  // Function to check if any value has changed
  const checkForChanges = () => {
    return (
      propertyName !== prevValues.current.propertyName ||
      defaultProperty !== prevValues.current.defaultProperty ||
      propertyType !== prevValues.current.propertyType ||
      apartmentUnit !== prevValues.current.apartmentUnit ||
      apartmentName !== prevValues.current.apartmentName ||
      address !== prevValues.current.address ||
      city !== prevValues.current.city ||
      propertyState !== prevValues.current.propertyState ||
      zipCode !== prevValues.current.zipCode ||
      numberBedrooms !== prevValues.current.numberBedrooms ||
      numberBathrooms !== prevValues.current.numberBathrooms ||

      numberHalfBathrooms !== prevValues.current.numberHalfBathrooms ||
      numberCeilingFans !== prevValues.current.numberCeilingFans ||
      nonMiniBlindsBinary !== prevValues.current.nonMiniBlindsBinary ||

      squareFeet !== prevValues.current.squareFeet ||
      meetOwnerAtDoorBinary !== prevValues.current.meetOwnerAtDoorBinary ||
      hiddenKeyBinary !== prevValues.current.hiddenKeyBinary ||
      unlockedBinary !== prevValues.current.unlockedBinary ||
      gateCommunityBinary !== prevValues.current.gateCommunityBinary ||
      propertyAccessNotes !== prevValues.current.propertyAccessNotes ||
      numberDogs !== prevValues.current.numberDogs ||
      numberCats !== prevValues.current.numberCats ||
      propertyPetsNotes !== prevValues.current.propertyPetsNotes
    );
  };  

  // Function to check if all fields are in their base state
  const checkIfAllFieldsAreBaseState = () => {
    return (
      (propertyName === initialState.propertyName || propertyName === '') &&      
      (propertyType === initialState.propertyType || propertyType === '') &&
      (apartmentUnit === initialState.apartmentUnit || apartmentUnit === '') &&
      (apartmentName === initialState.apartmentName || apartmentName === '') &&
      (address === initialState.address || address === '') &&
      (city === initialState.city || city === '') &&
      (propertyState === initialState.propertyState || propertyState === '') &&
      (zipCode === initialState.zipCode || zipCode === '') &&
      (numberBedrooms === initialState.numberBedrooms || numberBedrooms === '') &&
      (numberBathrooms === initialState.numberBathrooms || numberBathrooms === '') &&

      (numberHalfBathrooms === initialState.numberHalfBathrooms || numberHalfBathrooms === '') &&
      (numberCeilingFans === initialState.numberCeilingFans || numberCeilingFans === '') &&
      (nonMiniBlindsBinary === initialState.nonMiniBlindsBinary || nonMiniBlindsBinary === '') &&


      (squareFeet === initialState.squareFeet || squareFeet === '') &&
      (propertyAccessNotes === initialState.propertyAccessNotes || propertyAccessNotes === '') &&
      (numberDogs === initialState.numberDogs || numberDogs === '') &&
      (numberCats === initialState.numberCats || numberCats === '') &&
      (propertyPetsNotes === initialState.propertyPetsNotes || propertyPetsNotes === '')
    );
  };
  

  // Effect to monitor changes
  useEffect(() => {
    if (checkForChanges()) {
      if(checkIfAllFieldsAreBaseState()){
        setNeedsSave(false);
      } else {
        setNeedsSave(true);
      }       
    }

    // Update previous values
    prevValues.current = {
      propertyName,
      defaultProperty,
      propertyType,
      apartmentUnit,
      apartmentName,
      address,
      city,
      propertyState,
      zipCode,
      numberBedrooms,
      numberBathrooms,
      numberHalfBathrooms,
      numberCeilingFans,
      nonMiniBlindsBinary,
      squareFeet,    
      meetOwnerAtDoorBinary,
      hiddenKeyBinary,    
      unlockedBinary,
      gateCommunityBinary,   
      propertyAccessNotes,
      numberDogs,
      numberCats,
      propertyPetsNotes,
    };
  }, [
    propertyName,
    defaultProperty,
    propertyType,
    apartmentUnit,
    apartmentName,
    address,
    city,
    propertyState,
    zipCode,
    numberBedrooms,
    numberBathrooms,
    numberHalfBathrooms,
    numberCeilingFans,
    nonMiniBlindsBinary,
    squareFeet,    
    meetOwnerAtDoorBinary,
    hiddenKeyBinary,    
    unlockedBinary,
    gateCommunityBinary,   
    propertyAccessNotes,
    numberDogs,
    numberCats,
    propertyPetsNotes,
  ]);


  // Function to update prevValues with the newly loaded property data
  const updatePrevValuesWithLoadedProperty = (loadedProperty) => {
    prevValues.current = {
      ...prevValues.current,
      propertyName: loadedProperty.propertyName,
      defaultProperty: loadedProperty.defaultProperty,
      propertyType: loadedProperty.propertyType,
      apartmentUnit: loadedProperty.apartmentUnit,
      apartmentName: loadedProperty.apartmentName,
      address: loadedProperty.address,
      city: loadedProperty.city,
      propertyState: loadedProperty.propertyState,
      zipCode: loadedProperty.zipCode,
      numberBedrooms: loadedProperty.numberBedrooms,
      numberBathrooms: loadedProperty.numberBathrooms,

      numberHalfBathrooms: loadedProperty.numberHalfBathrooms,
      numberCeilingFans: loadedProperty.numberCeilingFans,
      nonMiniBlindsBinary: loadedProperty.nonMiniBlindsBinary,

      squareFeet: loadedProperty.squareFeet,    
      meetOwnerAtDoorBinary: loadedProperty.meetOwnerAtDoorBinary,
      hiddenKeyBinary: loadedProperty.hiddenKeyBinary,    
      unlockedBinary: loadedProperty.unlockedBinary,
      gateCommunityBinary: loadedProperty.gateCommunityBinary,   
      propertyAccessNotes: loadedProperty.propertyAccessNotes,
      numberDogs: loadedProperty.numberDogs,
      numberCats: loadedProperty.numberCats,
      propertyPetsNotes: loadedProperty.propertyPetsNotes,      
    };
  };  








  return (

    <div className="full-width-container">
      <Header />

      {showConfirmationDialog && (
        <ConfirmationDialog
          message="You have unsaved changes. Would you like to save them before creating a new property?"
          onConfirm={handleSaveBeforeNew} // Handle "Yes" option
          onCancel={handleNoSaveAndNew}    // Handle "No" option
        />
      )}

      <div className="content-container column with-padding-bottom">

        <div className="form-container">
          <h1>Property Information</h1>

          <ErrorBox errorTexts={formErrors} />

          {propertiesState.length > 0 && (
            <>
              {/* Dropdown for selecting saved properties */}
              <div className="form-group">
                <label className="form-label">Saved Properties:</label>
                <select
                  className="form-select"
                  value={selectedItem ? selectedItem.id : ''}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled>Select Property</option>
                  {dropdownItems.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.propertyName || item.formattedAddress}
                    </option>
                  ))}
                </select>
              </div>      
            </>
          )} 


          {isReadOnly && (
            <div className="note-text">
              Property is actively assigned and some fields cannot be edited.
            </div>
          )}

          {/* Address Lookup Section */}
          <div>
            <h2>Property Look Up</h2>

            {/* Property Type Selection */}
            <div className="form-group">
              <label className="form-label">Property Type:</label>
              <select
                className="form-select"
                value={propertyType}
                onChange={(e) => {
                  const selectedType = e.target.value;
                  dispatch(updateProperty({ propertyType: selectedType }));
                  if (selectedType === 'house') handleHouseSelection();
                }}
                disabled={isReadOnly}
              >
                <option value="house">House</option>
                <option value="apartment">Apartment</option>
              </select>
            </div>


            {/* Conditional UI for Apartment-specific Fields */}
            {propertyType === 'apartment' && (
              <div className="apartment-details">
                <div className="details-row">
                  <label className="form-label">Apartment Name:</label>
                  <input
                    type="text"
                    value={apartmentName || ''}
                    onChange={(e) => !isReadOnly && dispatch(updateProperty({ apartmentName: e.target.value }))}
                    placeholder="Enter apartment name"
                    className={`customer-input ${isReadOnly ? 'disabled-input' : ''}`}
                    disabled={isReadOnly}
                  />
                </div>
                <div className="details-row">
                  <label className="form-label">Apartment Unit:</label>
                  <input
                    type="text"
                    value={apartmentUnit || ''}
                    onChange={(e) => !isReadOnly && dispatch(updateProperty({ apartmentUnit: e.target.value }))}
                    placeholder="Enter apartment unit"
                    className={`customer-input ${isReadOnly ? 'disabled-input' : ''}`}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            )}

            {/* Render ErrorBox with propertyLookupErrors */}
            <ErrorBox errorTexts={propertyLookupErrors} />


            {!isReadOnly && (
              <>
                <label className="form-label">Address Lookup:</label>
                <div className="input-and-button-container">
                  <div className="input-container">
                    <input
                      ref={inputRef}
                      type="text"
                      value={inputValue}
                      onChange={(e) => handleInputChange(e.target.value)}
                      placeholder="Enter an address"
                      className="address-input"
                    />
                  </div>
                </div>
              </>
            )}

            <section className="suggestions-container">
              {suggestions.length > 0 && suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.description}
                </div>
              ))}
            </section>
          </div>

          {/* Property Details Section */}
          {address && (
            <main className="tempMapContainer">
              <section className="main-feature">
                <div className="address-details">
                  {/* <h2>Address Details</h2> */}
                  <div className="details-row">
                    <label>Street Address:</label>
                    <p>{address || 'N/A'}</p>
                  </div>
                  <div className="details-row">
                    <label>City:</label>
                    <p>{city || 'N/A'}</p>
                  </div>
                  <div className="details-row">
                    <label>State:</label>
                    <p>{propertyState || 'N/A'}</p>
                  </div>
                  <div className="details-row">
                    <label>Zip Code:</label>
                    <p>{zipCode || 'N/A'}</p>
                  </div>
                </div>

                <div className="map-container">
                  {/* <h2>Map View</h2> */}
                  {latitude && longitude ? (
                    <GoogleMapComponent location={{ latitude, longitude }} />
                  ) : (
                    <p></p>
                  )}
                </div>
              </section>
            </main>
          )}  

          <div className="form-group marginTop10">
            <label className="form-label">Property Save Name:</label>
            <input
              type="text"
              className="form-input"
              value={propertyName || ''}  // Ensure an empty string if propertyName is null
              onChange={(e) => dispatch(updateProperty({ propertyName: e.target.value }))}
            />
          </div>

          <div className="checkbox-container">
            <Checkbox
              label="Set as default property"
              fontSize="unset"
              checked={defaultProperty}
              onChange={() => dispatch(updateProperty({ defaultProperty: !defaultProperty }))}
            />
          </div>


          <h2>Property Details</h2>

          <div className="form-group incrementor-group">
            <label className="form-label">Number of Bedrooms:</label>
            <NumberIncrementor
              value={numberBedrooms || 0}
              onIncrement={() => dispatch(updateProperty({ numberBedrooms: (numberBedrooms || 0) + 1 }))}
              onDecrement={() => dispatch(updateProperty({ numberBedrooms: Math.max(0, numberBedrooms - 1) }))}
              alignment="left"
              marginLeft="20px"
              disabled={isReadOnly}
            />
          </div>

          <div className="form-group incrementor-group">
            <label className="form-label">Number of Bathrooms:</label>
            <NumberIncrementor
              value={numberBathrooms || 0}
              onIncrement={() => dispatch(updateProperty({ numberBathrooms: (numberBathrooms || 0) + 1 }))}
              onDecrement={() => dispatch(updateProperty({ numberBathrooms: Math.max(0, numberBathrooms - 1) }))}
              alignment="left"
              marginLeft="20px"
              disabled={isReadOnly}
            />
          </div>

          {/* Half Bathrooms */}
          <div className="form-group incrementor-group">
            <label className="form-label">Number of Half Bathrooms:</label>
            <NumberIncrementor
              value={numberHalfBathrooms || 0}
              onIncrement={() => dispatch(updateProperty({ numberHalfBathrooms: (numberHalfBathrooms || 0) + 1 }))}
              onDecrement={() => dispatch(updateProperty({ numberHalfBathrooms: Math.max(0, numberHalfBathrooms - 1) }))}
              alignment="left"
              marginLeft="20px"
              disabled={isReadOnly}
            />
          </div>

          {/* Ceiling Fans */}
          <div className="form-group incrementor-group">
            <label className="form-label">Number of Ceiling Fans:</label>
            <NumberIncrementor
              value={numberCeilingFans || 0}
              onIncrement={() => dispatch(updateProperty({ numberCeilingFans: (numberCeilingFans || 0) + 1 }))}
              onDecrement={() => dispatch(updateProperty({ numberCeilingFans: Math.max(0, numberCeilingFans - 1) }))}
              alignment="left"
              marginLeft="20px"
              disabled={isReadOnly}
            />
          </div>

          {/* Conditional Checkbox for Non-Mini Blinds */}
          {cleaningType === 'deep' && (
            <div className="form-group mini-blinds-container">
              {/* Label for Non-Mini Blinds */}
              <div className="form-label">
                <label>Dust Non-Mini Blinds?</label>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  label="Yes, include non-mini blinds in deep cleaning."
                  checked={nonMiniBlindsBinary}
                  fontSize='unset'
                  onChange={() => dispatch(updateProperty({ nonMiniBlindsBinary: !nonMiniBlindsBinary }))}
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <label className="form-label">Square Feet:</label>
            <input
              type="number"
              // className="form-input"
              value={squareFeet || ''}
              onChange={(e) => dispatch(updateProperty({ squareFeet: parseInt(e.target.value, 10) }))}
              className={`customer-input ${isReadOnly ? 'disabled-input' : ''}`}
              disabled={isReadOnly}
            />
          </div>

          <h2>Property Access</h2>

          <div className="form-group">
            <Checkbox
              label="Meet owner at front door on arrival"
              fontSize="unset"
              checked={meetOwnerAtDoorBinary}
              onChange={() => dispatch(updateProperty({ meetOwnerAtDoorBinary: !meetOwnerAtDoorBinary }))}
            />
          </div>

          <div className="form-group">
            <Checkbox
              label="Hidden key"
              fontSize="unset"
              checked={hiddenKeyBinary}
              onChange={() => dispatch(updateProperty({ hiddenKeyBinary: !hiddenKeyBinary }))}
            />
          </div>

          <div className="form-group">
            <Checkbox
              label="Unlocked"
              fontSize="unset"
              checked={unlockedBinary}
              onChange={() => dispatch(updateProperty({ unlockedBinary: !unlockedBinary }))}
            />
          </div>

          <div className="form-group">
            <Checkbox
              label="Gated community"
              fontSize="unset"
              checked={gateCommunityBinary}
              onChange={() => dispatch(updateProperty({ gateCommunityBinary: !gateCommunityBinary }))}
            />
          </div>

          <div className="form-group">
            <label className="form-label">Property Access Notes:</label>
            <textarea
              className="form-textarea"
              value={propertyAccessNotes}
              onChange={(e) => dispatch(updateProperty({ propertyAccessNotes: e.target.value }))}
            />
          </div>

          <h2>Property Pets</h2>

          <div className="form-group incrementor-group">
            <label className="form-label">Number of Dogs:</label>
            <NumberIncrementor
              value={numberDogs || 0}
              onIncrement={() => dispatch(updateProperty({ numberDogs: (numberDogs || 0) + 1 }))}
              onDecrement={() => dispatch(updateProperty({ numberDogs: Math.max(0, numberDogs - 1) }))}
              alignment="left"
              marginLeft="20px"
            />
          </div>

          <div className="form-group incrementor-group">
            <label className="form-label">Number of Cats:</label>
            <NumberIncrementor
              value={numberCats || 0}
              onIncrement={() => dispatch(updateProperty({ numberCats: (numberCats || 0) + 1 }))}
              onDecrement={() => dispatch(updateProperty({ numberCats: Math.max(0, numberCats - 1) }))}
              alignment="left"
              marginLeft="20px"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Property Pets Notes:</label>
            <textarea
              className="form-textarea"
              value={propertyPetsNotes}
              onChange={(e) => dispatch(updateProperty({ propertyPetsNotes: e.target.value }))}
            />
          </div>

          <ErrorBox errorTexts={formErrors} />
          
        </div>
      </div>

      {/* Fixed Bottom Bar for Buttons */}
      <div className="fixed-bottom-bar">
        {/* Save Button - always visible */}
        <button
          className="form-button form-save-button margin10"
          onClick={handleSaveChanges}
          disabled={!needsSave}
        >
          Save Changes
        </button>

        {/* Conditionally show New Property and Delete Property buttons */}
        {propertiesState.length > 0 && (
          <>
            {/* New Property Button */}
            <button
              className="form-button form-new-button margin10"
              onClick={confirmSaveBeforeNew}
            >
              Create New Property
            </button>

            {/* Delete Property Button */}
            <button
              className="form-button form-delete-button margin10"
              onClick={confirmDeleteProperty}
            >
              Delete Property
            </button>
          </>
        )} 

        <div className="button-bar">
          <button
            className="form-button form-next-button margin10"
            onClick={handleNextPress}
          >
            Next
          </button>
        </div>
      </div>

    </div>
  );


};

export default PropertyScreen;
