// src/screens/ToDoListScreen.js
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';
import { updateTasks } from '../slices/tasksSlice';
import { taskSubscriptionManager } from '../thunks/taskSubscriptionManager';

import { getFirebaseToken } from '../utils/firebaseTokenUtil';

import Header from '../components/Header'; 
import ToDoCard from '../components/ToDoCard'; 


const ToDoListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tasksState = useSelector((state) => state.tasks);
  const propertyId = useSelector((state) => state.property.id);
  const jobId = useSelector((state) => state.job.id);

  const subscribedTaskImages = useSelector((state) => state.subscribedTasks);
  const [isLoading, setIsLoading] = useState(false);

  console.log("propertyId", propertyId);

  // Load tasks from the server when the screen is focused or propertyId changes
  const loadTasksFromServer = useCallback(async () => {
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token found');
      }

      const response = await axios.post(
        `${apiBaseUrl}/load-tasks`,
        { propertyId },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(updateTasks(response.data.savedTasks));
        dispatch(taskSubscriptionManager({ type: 'add', tasks: response.data.savedTasks }));
      } else {
        console.error('Error loading tasks');
      }
    } catch (error) {
      console.error('Failed to load tasks:', error);
    }
  }, [dispatch, propertyId]);


  // Initial load or reload when propertyId changes
  useEffect(() => {
    loadTasksFromServer();
  }, [loadTasksFromServer]);


  const handleDelete = async (taskId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this task?");
  
    if (confirmDelete) {
      if (tasksState.length > 0) {
        await saveAllTasks();  // Save all tasks before deletion
      }
      await deleteTask(taskId);  // Delete the specified task
    } else {
      console.log("Task deletion canceled.");
    }
  };  


  const deleteTask = async (taskId) => {
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token found');
      }

      const response = await axios.delete(
        `${apiBaseUrl}/delete-task`,
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
          data: { jobId, taskId }  // Use jobId and taskId in the request
        }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(updateTasks(response.data.savedTasks));  // Update tasks in the store
        dispatch(taskSubscriptionManager({ type: 'sync', tasks: response.data.savedTasks })); // Sync task subscriptions
        console.log(`Task with ID ${taskId} deleted successfully.`);
      } else {
        console.error('Error deleting the task');
      }
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };


  // Helper function to save all tasks (if any) and then create a new task
  const handleCreateNewTask = async () => {
    if (tasksState.length > 0) {
      await saveAllTasks();  // Save all tasks before creating a new one
    }
    await createNewTask();  // Create a new task
    alert('New task has been created successfully.');  // Show alert after task creation
  };


  const createNewTask = async () => {
    setIsLoading(true);
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token found');
      }

      const response = await axios.post(
        `${apiBaseUrl}/create-task`,
        { propertyId },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );

      if (response.status === 200 && response.data.success) {
        dispatch(updateTasks(response.data.savedTasks));
        dispatch(taskSubscriptionManager({ type: 'sync', tasks: response.data.savedTasks })); // Sync task subscriptions
      } else {
        console.error('Error creating new task');
      }
    } catch (error) {
      console.error('Failed to create task:', error);
    } finally {
      setIsLoading(false);
    }
  };


  // Helper function to save all tasks and show an alert
  const handleSaveChanges = async () => {
    await saveAllTasks();
    alert('All changes have been saved successfully.');
  };


  const saveAllTasks = async () => {
    try {

      console.log("Current tasksState before saving:", JSON.stringify(tasksState, null, 2));
      
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token found');
      }
  
      const response = await axios.post(
        `${apiBaseUrl}/save-all-tasks`,
        {
          propertyId,
          tasks: tasksState  
        },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );
  
      if (response.status === 200 && response.data.success) {
        dispatch(updateTasks(response.data.savedTasks));
        dispatch(taskSubscriptionManager({ type: 'sync', tasks: response.data.savedTasks })); // Sync task subscriptions
      } else {
        console.error('Error saving tasks');
      }
    } catch (error) {
      console.error('Failed to save tasks:', error);
    }
  };


  const handleNextPress = () => {
    navigate('/scheduling'); // Redirect to the SchedulingScreen
  };


  return (
    <div className="full-width-container">
      <Header />
      <div className="content-container column with-padding-bottom">

        <div className='form-container'>
          <h1>To Do List</h1>
        </div>       
  
        {tasksState.length > 0 ? (
          tasksState.map((task, index) => (
            <ToDoCard
              key={task.id}
              number={index + 1}
              toDoImage={subscribedTaskImages[task.id] || null}
              toDoText={task.taskCustomerText}
              taskId={task.id}
              handleDelete={() => handleDelete(task.id)}
            />
          ))
        ) : (
          <p>No tasks currently added</p>
        )}
      </div> 



      {/* Fixed Bottom Bar for Buttons */}
      <div className="fixed-bottom-bar">
        <div className="button-bar">
          {tasksState.length > 0 && (
            <button
              className="form-button regular-save-button margin10"
              onClick={handleSaveChanges}
            >
              Save All Changes
            </button>
          )}
          <button
            className="form-button regular-new-task-button margin10"
            onClick={handleCreateNewTask}
          >
            Create New Task
          </button>
          <button
            className="form-button regular-new-task-button margin10"
            onClick={handleNextPress} // Add onClick to navigate to scheduling
          >
            Next
          </button>
        </div>
      </div>


    </div>
  );

};

export default ToDoListScreen;