// src/screens/PhoneLoginScreen.js
import React from 'react';
import PhoneLogin from '../components/PhoneLogin';

function PhoneLoginScreen() {
  return (
    <div>
      <h1>Test Phone Login</h1>
      <PhoneLogin onVerificationSuccess={() => console.log('Phone login successful!')} />
    </div>
  );
}

export default PhoneLoginScreen;
