import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';

import Header from '../components/Header';
import ErrorBox from '../components/ErrorBox';
import { updateUser } from '../slices/userSlice';

function CompleteSignupScreen() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const phoneNumber = user.phone; // Grab phone number from Redux state

  // Format the phone number for display
  const formattedPhoneNumber = phoneNumber
    ? `(${phoneNumber.slice(2, 5)}) - ${phoneNumber.slice(5, 8)} - ${phoneNumber.slice(8)}`
    : '';

  // Redirect if user has completed signup
  useEffect(() => {
    if (user.firstName?.trim() && user.lastName?.trim()) {
      navigate('/');
    }
  }, [user.firstName, user.lastName, navigate]);

  // Validate inputs and submit the form
  const handleSubmit = async () => {
    let newErrors = [];

    // Validate name fields
    const namePattern = /^[a-zA-Z-' ]+$/;
    if (!namePattern.test(firstName.trim()) || firstName.length < 2 || firstName.length > 50) {
      newErrors.push("Please enter a valid first name (2-50 characters).");
    }
    if (!namePattern.test(lastName.trim()) || lastName.length < 2 || lastName.length > 50) {
      newErrors.push("Please enter a valid last name (2-50 characters).");
    }
    if (email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      newErrors.push("Please enter a valid email or leave it empty.");
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    // Send user data to the backend
    try {
      const response = await axios.post(`${apiBaseUrl}/update-user-details`, {
        phoneNumber,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email,
      });

      if (response.data && response.data.success) {
        dispatch(updateUser(response.data.user));
        setErrors([]); // Clear errors on success
        navigate('/servicehome'); // Navigate to the service home page
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrors([error.response.data.message]);
      } else {
        setErrors(['An error occurred. Please try again.']);
      }
    }
  };

  return (
    <div className="full-width-container with-padding-bottom">
      <Header />

      <div className="content-container column">
        <div className="form-container" style={{ maxWidth: '600px' }}>
          <h1>Complete Your Account</h1>

          {/* Display the user's account number (phone number) */}
          <div className="form-group">
            <label className="form-label">User Account Number:</label>
            <div className="form-input" style={{ backgroundColor: '#f9f9f9', color: '#333' }}>
              {formattedPhoneNumber}
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="first-name">First Name</label>
            <input
              id="first-name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter your first name"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="last-name">Last Name</label>
            <input
              id="last-name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter your last name"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="email">Email (Optional)</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email (optional)"
              className="form-input"
            />
          </div>

          {/* Centered submit button */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button className="blue-button" onClick={handleSubmit}>
              Complete Signup
            </button>
          </div>

          {/* ErrorBox to display validation errors */}
          <ErrorBox isVisible={errors.length > 0} errorTexts={errors} />
        </div>
      </div>
    </div>
  );
}

export default CompleteSignupScreen;



// // src/screens/CompleteSignupScreen.js
// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { apiBaseUrl } from '../apiConfig';

// import Header from '../components/Header';
// import ErrorBox from '../components/ErrorBox';
// import { updateUser } from '../slices/userSlice';

// function CompleteSignupScreen() {
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [email, setEmail] = useState('');
//   const [errors, setErrors] = useState([]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const phoneNumber = useSelector(state => state.user.phone); // Grab phone number from Redux state

//   // Format the phone number for display
//   const formattedPhoneNumber = phoneNumber
//     ? `(${phoneNumber.slice(2, 5)}) - ${phoneNumber.slice(5, 8)} - ${phoneNumber.slice(8)}`
//     : '';

//   // Validate inputs and submit the form
//   const handleSubmit = async () => {
//     let newErrors = [];

//     // Validate name fields
//     const namePattern = /^[a-zA-Z-' ]+$/;
//     if (!namePattern.test(firstName.trim()) || firstName.length < 2 || firstName.length > 50) {
//       newErrors.push("Please enter a valid first name (2-50 characters).");
//     }
//     if (!namePattern.test(lastName.trim()) || lastName.length < 2 || lastName.length > 50) {
//       newErrors.push("Please enter a valid last name (2-50 characters).");
//     }
//     if (email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
//       newErrors.push("Please enter a valid email or leave it empty.");
//     }

//     if (newErrors.length > 0) {
//       setErrors(newErrors);
//       return;
//     }

//     // Send user data to the backend
//     try {
//       const response = await axios.post(`${apiBaseUrl}/update-user-details`, {
//         phoneNumber,
//         firstName: firstName.trim(),
//         lastName: lastName.trim(),
//         email,
//       });

//       if (response.data && response.data.success) {
//         dispatch(updateUser(response.data.user));
//         setErrors([]); // Clear errors on success
//         navigate('/servicehome'); // Navigate to the service home page
//       }
//     } catch (error) {
//       if (error.response && error.response.data && error.response.data.message) {
//         setErrors([error.response.data.message]);
//       } else {
//         setErrors(['An error occurred. Please try again.']);
//       }
//     }
//   };

//   return (
//     <div className="full-width-container with-padding-bottom">
//       <Header />

//       <div className="content-container column">
//         <div className="form-container" style={{ maxWidth: '600px' }}>
//           <h1>Complete Your Account</h1>

//           {/* Display the user's account number (phone number) */}
//           <div className="form-group">
//             <label className="form-label">User Account Number:</label>
//             <div className="form-input" style={{ backgroundColor: '#f9f9f9', color: '#333' }}>
//               {formattedPhoneNumber}
//             </div>
//           </div>

//           <div className="form-group">
//             <label className="form-label" htmlFor="first-name">First Name</label>
//             <input
//               id="first-name"
//               type="text"
//               value={firstName}
//               onChange={(e) => setFirstName(e.target.value)}
//               placeholder="Enter your first name"
//               className="form-input"
//             />
//           </div>

//           <div className="form-group">
//             <label className="form-label" htmlFor="last-name">Last Name</label>
//             <input
//               id="last-name"
//               type="text"
//               value={lastName}
//               onChange={(e) => setLastName(e.target.value)}
//               placeholder="Enter your last name"
//               className="form-input"
//             />
//           </div>

//           <div className="form-group">
//             <label className="form-label" htmlFor="email">Email (Optional)</label>
//             <input
//               id="email"
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="Enter your email (optional)"
//               className="form-input"
//             />
//           </div>

//           {/* Centered submit button */}
//           <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
//             <button className="blue-button" onClick={handleSubmit}>
//               Sign In
//             </button>
//           </div>

//           {/* ErrorBox to display validation errors */}
//           <ErrorBox isVisible={errors.length > 0} errorTexts={errors} />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CompleteSignupScreen;
