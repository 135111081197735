// src/screens/SchedulingScreen.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Checkbox from '../components/Checkbox';
import Header from '../components/Header';
import CalendarComponent from '../components/CalendarComponent';
import SectionHeader from '../components/SectionHeader';
import LoadingIndicator from '../components/LoadingIndicator';
import DaysContainer from '../components/DaysContainer';
import CleanersContainer from '../components/CleanersContainer';
import MultiCleanerContainer from '../components/MultiCleanerContainer';
import ErrorBox from '../components/ErrorBox';

import './SchedulingScreen.css';

import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';
import { getFirebaseToken } from '../utils/firebaseTokenUtil';

import { updateJob } from '../slices/jobSlice';
import { setDate, resetDate } from '../slices/desiredDateSlice';
import { setRecurringDay, resetRecurringDay } from '../slices/desiredRecurringDaySlice';
import { setFrequency, resetFrequency } from '../slices/serviceFrequencySlice';
import { setSoloCleaner, setMultipleCleaners, resetCleaners } from '../slices/desiredCleanersSlice';
import { setAvailableRecurringDays, resetAvailableRecurringDays } from '../slices/availableRecurringDaysSlice';
import { setRecurringSoloDayCleaners, resetRecurringSoloDayCleaners } from '../slices/recurringSoloDayCleanersSlice';
import { setRecurringMultiDayCleaners, resetRecurringMultiDayCleaners } from '../slices/recurringMultiDayCleanersSlice';



function SchedulingScreen() {

  const navigate = useNavigate();
  
  const [availableDates, setAvailableDates] = useState([]);
  const [availableDatesAndMultiShiftTypes, setAvailableDatesAndMultiShiftTypes] = useState([]); // New state for dates and types

  const [isLoading, setIsLoading] = useState(false); 
  const [calendarLoading, setCalendarLoading] = useState(false);

  const [aggregatedAvailabilityState, setAggregatedAvailabilityState] = useState({});

  //general validation errors for screen
  const [errors, setErrors] = useState([]);


  //Redux state
  const dispatch = useDispatch();
  const selectedDate = useSelector(state => state.desiredDate);
  const selectedServiceFrequency = useSelector(state => state.serviceFrequency);

  const availableDays = useSelector(state => state.availableRecurringDays);
  const selectedDay = useSelector(state => state.desiredRecurringDay);
  const soloDayCleaners = useSelector(state => state.recurringSoloDayCleaners);
  const multiDayCleaners = useSelector(state => state.recurringMultiDayCleaners);

  const jobId = useSelector((state) => state.job.id);
  const numberOfCleaners = useSelector((state) => state.job.numberOfCleaners);
  const fullShiftOnlyNumberOfCleaners = useSelector((state) => state.job.fullShiftOnlyNumberOfCleaners);
  const multiShiftType = useSelector((state) => state.job.multiShiftType);

  //full or normal shift type for multi cleaners
  const [shiftType, setShiftType] = useState('normal');

  console.log("jobId", jobId);


  const handleSelectedSoloCleanerChange = (cleaners) => {
    if (Array.isArray(cleaners) && cleaners.length === 0) {
      console.log("Resetting solo cleaner");
      dispatch(resetCleaners());
    } else if (cleaners.length === 1) {
      const soloCleaner = cleaners[0];      
      const soloCleanerId = soloCleaner.cleanerUserId;
      console.log("Selected solo cleaner", soloCleanerId);
      dispatch(setSoloCleaner(soloCleanerId));
    }
  };
  

  const handleSelectedMultiCleanersChange = (cleaners) => {
    if (Array.isArray(cleaners) && cleaners.length === 0) {
      console.log("Resetting multiple cleaners");
      dispatch(resetCleaners());
    } else {      
      const multipleCleanerIds = cleaners.map(cleaner => cleaner.cleanerUserId);
      console.log("Selected multiple cleaners", multipleCleanerIds);
      dispatch(setMultipleCleaners(multipleCleanerIds));
    }
  };


  const handleDayPress = (day, frequency) => {
    // Call the function to update the cleaners for the selected day
    const effectiveNumberOfCleaners = shiftType === 'large' ? fullShiftOnlyNumberOfCleaners : numberOfCleaners;

    updateCleanersForDay(day, effectiveNumberOfCleaners, frequency);

    // Dispatch the action to set the recurring day
    dispatch(setRecurringDay(day));

    console.log(day); // Logging the selected day
  };  
  

  const tagShiftSlot = (cleanersArray, shiftSlot) => {
    if (cleanersArray.length === 0) {
      return [];
    }
    
    return cleanersArray.map(cleanerGroup => {
      return cleanerGroup.map(cleaner => {
        return {
          ...cleaner,
          shiftSlot
        };
      });
    });
  };


  const tagShiftSlotWeekly = (cleanersArray, shiftSlot) => {
    if (cleanersArray.length === 0) {
        return [];
    }

    const taggedCleaners = cleanersArray.map(cleaner => {
        return {
            ...cleaner,
            shiftSlot
        };
    });

    // Wrap the result inside an array to make the structure consistent with tagShiftSlot
    return [taggedCleaners];
  };

    
  const updateCleanersForDay = (day, numberOfCleaners, frequency) => {
    let cleanersForTheDay = {};

    if (numberOfCleaners === 1) {
        const key = `${day}All`;
        cleanersForTheDay = aggregatedAvailabilityState[key] || [];

        dispatch(setRecurringSoloDayCleaners(cleanersForTheDay));
        // setSoloDayCleaners(cleanersForTheDay);
    } else {
        const morningKey = `${day}Morning`;
        const afternoonKey = `${day}Afternoon`;

        let morningCleaners = aggregatedAvailabilityState[morningKey] || [];
        let afternoonCleaners = aggregatedAvailabilityState[afternoonKey] || [];
        
        // Use the appropriate function based on frequency
        const tagShiftSlotFunction = frequency === 'weekly' ? tagShiftSlotWeekly : tagShiftSlot;
        
        morningCleaners = tagShiftSlotFunction(morningCleaners, 'morning');
        afternoonCleaners = tagShiftSlotFunction(afternoonCleaners, 'afternoon'); 

        cleanersForTheDay = {
            Morning: morningCleaners,
            Afternoon: afternoonCleaners
        };

        console.log(cleanersForTheDay);

        dispatch(setRecurringMultiDayCleaners(cleanersForTheDay));
        // setMultiDayCleaners(cleanersForTheDay);
    }
  };


  const getDaysWithAvailability = (aggregatedAvailability, numberOfCleaners) => {

    const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    
    // For a single cleaner structure
    if (numberOfCleaners === 1) {
      return daysOrder.filter(day => {
        const key = `${day}All`;
        return aggregatedAvailability[key] && aggregatedAvailability[key].length > 0;
      });
    }
    
    // For a multi-cleaner structure
    return daysOrder.filter(day => {
      const morningKey = `${day}Morning`;
      const afternoonKey = `${day}Afternoon`;
  
      return (aggregatedAvailability[morningKey] && aggregatedAvailability[morningKey].length > 0) ||
             (aggregatedAvailability[afternoonKey] && aggregatedAvailability[afternoonKey].length > 0);
    });
  };
  

  const updateCleanersDays = (data) => {  

    const aggregatedAvailability = data.aggregatedAvailability;

    setAggregatedAvailabilityState(aggregatedAvailability); 

    const effectiveNumberOfCleaners = shiftType === 'large' ? fullShiftOnlyNumberOfCleaners : numberOfCleaners;
  
    const daysWithAvailability = getDaysWithAvailability(aggregatedAvailability, effectiveNumberOfCleaners);
    // console.log("aggregatedAvailability", aggregatedAvailability);
  
    // Update the available days state
    dispatch(setAvailableRecurringDays(daysWithAvailability));
   // setAvailableDays(daysWithAvailability);
  };


  
  // Function to fetch available recurring cleaners based on frequency
  const fetchAvailableRecurringCleaners = async (frequency) => {
    setIsLoading(true); // Turn on the loading indicator
    // Reset values to their default state    
    setAggregatedAvailabilityState({});
    dispatch(resetAvailableRecurringDays());
    dispatch(resetRecurringSoloDayCleaners());
    dispatch(resetRecurringMultiDayCleaners());
    setShiftType("normal");

    try {
      // Retrieve the Firebase ID token
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token');
      }

      // Make the call to the backend with the token
      const response = await axios.get(
        `${apiBaseUrl}/available-recurring-cleaners/${jobId}/${frequency}`,
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );

      // Check the response data for success
      if (response.data.success) {
        updateCleanersDays(response.data);
        setShiftType(response.data.normalOrLarge); // Update the shift type
      } else {
        console.error('Success is false. Please handle this case.');
      }
    } catch (error) {
      console.error('Error retrieving available cleaners: ', error);
    } finally {
      setIsLoading(false); // Turn off the loading indicator irrespective of success or failure
    }
  };


  const handleCheckboxChange = (newValue) => { 
    dispatch(resetAvailableRecurringDays());
    dispatch(resetRecurringSoloDayCleaners());
    dispatch(resetRecurringMultiDayCleaners());
    dispatch(resetCleaners());
  
    dispatch(setFrequency(newValue));
  
    // Dispatch the action to reset the recurring day
    dispatch(resetRecurringDay());
  
    // List of valid frequencies
    const validFrequencies = ['monthly', 'weekly', 'biweekly'];
  
    // Check if the new value is a valid frequency
    if (validFrequencies.includes(newValue)) {
      fetchAvailableRecurringCleaners(newValue);
    } else if (newValue === 'once') {
      // dispatch(resetCleaners());
    }
  };
  


  const validateScheduleInput = () => {
    const errors = [];
  
    // Check if a date is selected
    if (!selectedDate) {
      errors.push('Please select a desired service date.');
    }
  
    // Check if a service frequency is selected
    if (!selectedServiceFrequency) {
      errors.push('Please select a service frequency.');
    } else if (selectedServiceFrequency !== 'once') {
      // If the frequency is not 'once', check if a day is selected
      if (!selectedDay) {
        errors.push('Please select a day for the recurring service.');
      }
    }
  
    setErrors(errors);
    return errors.length === 0; // Return true if no errors, false otherwise
  };
  

  const handleSchedule = async () => {
    // Validate input before proceeding
    if (!validateScheduleInput()) {
      window.alert('Please address the listed errors before proceeding.');
      return; // Exit if there are validation errors
    }
  
    navigate('/payment');
     
  };
  
  
  const checkAndResetDateIfNotAvailable = (availableDates) => {
    if (selectedDate && !availableDates.includes(selectedDate)) {
      dispatch(resetDate());
    }
  };    


  const handleLoadCalendar = async () => {
    setCalendarLoading(true); // Start loading
    try {
        const firebaseIdToken = await getFirebaseToken();
        if (!firebaseIdToken) {
            throw new Error('No Firebase ID token');
        }

        const response = await axios.get(
            `${apiBaseUrl}/available-dates-by-region/${jobId}`,
            { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
        );
  
        console.log('Received available dates and multiShiftType: ', response.data);
        const { availableDates, availableDatesAndMultiShiftTypes } = response.data;
      
        setAvailableDates(availableDates); // Set the available dates from the response
        setAvailableDatesAndMultiShiftTypes(availableDatesAndMultiShiftTypes); // Set the available dates and types
        checkAndResetDateIfNotAvailable(availableDates);
    } catch (error) {
        console.error('Error retrieving available dates: ', error);
    } finally {
        setCalendarLoading(false); // Stop loading
    }
  };

  
  // You might want to call handleNextPress function here to immediately fetch available dates when the component mounts
  useEffect(() => {
    handleLoadCalendar();
  }, []);


  const handleDateSelection = (date) => {    
    dispatch(setDate(date));
    
    // Find the multiShiftType for the selected date
    const selectedDateType = availableDatesAndMultiShiftTypes.find(item => item.date === date);
    if (selectedDateType && selectedDateType.multiShiftType) {
      console.log(`Selected date: ${date}, Multi shift type: ${selectedDateType.multiShiftType}`);
      dispatch(updateJob({ multiShiftType: selectedDateType.multiShiftType })); // Dispatch the updateJob action with multiShiftType
    } else {
      console.log(`Selected date: ${date}, Multi shift type: Not found`);
    }
  };
  


  const areCleanersAvailable = soloDayCleaners.length > 0 || 
    (multiDayCleaners && (multiDayCleaners.Morning?.length > 0 || multiDayCleaners.Afternoon?.length > 0));


  const formatDateWithSuffix = (dateString) => {
    if (!dateString) return '';

    // Split the date string to avoid timezone conversion
    const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));

    // Create the date object as a local date
    const dateObj = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date

    const monthNames = ["January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"];

    let suffix = 'th';

    if (day === 1 || day === 21 || day === 31) suffix = 'st';
    else if (day === 2 || day === 22) suffix = 'nd';
    else if (day === 3 || day === 23) suffix = 'rd';

    const monthName = monthNames[month - 1]; // month is 1-indexed here
    return `${monthName} ${day}${suffix}`;
  };


  // Helper function call
  const nextAvailableDate = availableDates.length > 0 ? formatDateWithSuffix(availableDates[0]) : null;


  const getEffectiveNumberOfCleaners = () => {
    return shiftType === 'large' ? fullShiftOnlyNumberOfCleaners : numberOfCleaners;
  };


  return (
    <div className="full-width-container">

      <Header />

      <div className="content-container column with-padding-bottom">

        <div className='form-container'>
          <h1>Scheduling</h1>
        </div>      

        <CalendarComponent
          availableDates={availableDates}
          onDateSelected={handleDateSelection}  
          initialSelectedDate={selectedDate}        
        />

        <div className="innerContainer">  

          <ErrorBox isVisible={errors.length > 0} errorTexts={errors} width='unset' />

          <SectionHeader 
            text="Availability" 
            backgroundColor="#333333" 
            fontColor="white" 
            textAlign="center"
            marginTop={20}
            marginBottom={10}
            padding={0}
          />          

          {calendarLoading ? (
            <LoadingIndicator isLoading={calendarLoading} text="Loading calendar..." alignment="center" />
          ) : (
            <div className="availability-container">
              {nextAvailableDate ? (
                <span className="availabilityText">
                  First service option available on {nextAvailableDate}
                </span>
              ) : (
                <span className="availabilityText">
                  No dates are currently available for the job
                </span>
              )}
            </div>
          )}


          <SectionHeader 
            text="Service Frequency" 
            backgroundColor="#333333" 
            fontColor="white" 
            textAlign="center"
            marginTop={5}
            marginBottom={10}
            padding={0}
          />          

          <div className="checkbox-wrapper">
            <div className="checkbox-screen-container">
              <Checkbox 
                label="One time"
                checked={selectedServiceFrequency === 'once'}
                onChange={() => handleCheckboxChange('once')}
              />
            </div>
          </div>

          <div className="checkbox-wrapper">
            <div className="checkbox-screen-container">
              <Checkbox 
                label="Monthly"
                checked={selectedServiceFrequency === 'monthly'}
                onChange={() => handleCheckboxChange('monthly')}
              />
            </div>
          </div>

          {selectedServiceFrequency === 'monthly' && (
            <>
              <LoadingIndicator isLoading={isLoading} text="Loading available days..." alignment="center" />
              <DaysContainer
                days={availableDays}
                onDayPress={handleDayPress}
                frequency="monthly" 
                isLoading={isLoading}
                areCleanersAvailable={areCleanersAvailable}
              />

              {soloDayCleaners.length > 0 && (
                <CleanersContainer
                  cleaners={soloDayCleaners}
                  numberOfCleaners={getEffectiveNumberOfCleaners()}
                  onSelectedCleanersChange={handleSelectedSoloCleanerChange}
                />
              )}

              {multiDayCleaners && (multiDayCleaners.Morning?.length > 0 || multiDayCleaners.Afternoon?.length > 0) && (
                <MultiCleanerContainer
                  cleaners={multiDayCleaners}
                  numberOfCleaners={getEffectiveNumberOfCleaners()}
                  onSelectedCleanersChange={handleSelectedMultiCleanersChange}
                />
              )}
            </>
          )}

          <div className="checkbox-wrapper">
            <div className="checkbox-screen-container">
              <Checkbox 
                label="Biweekly"
                checked={selectedServiceFrequency === 'biweekly'}
                onChange={() => handleCheckboxChange('biweekly')}
              />
            </div>
          </div>

          {selectedServiceFrequency === 'biweekly' && (
            <>
              <LoadingIndicator isLoading={isLoading} text="Loading available days..." alignment="center" />
              <DaysContainer
                days={availableDays}
                onDayPress={handleDayPress}
                frequency="biweekly" 
                isLoading={isLoading}
                areCleanersAvailable={areCleanersAvailable}
              />

              {soloDayCleaners.length > 0 && (
                <CleanersContainer
                  cleaners={soloDayCleaners}
                  numberOfCleaners={getEffectiveNumberOfCleaners()}
                  onSelectedCleanersChange={handleSelectedSoloCleanerChange}
                />
              )}

              {multiDayCleaners && (multiDayCleaners.Morning?.length > 0 || multiDayCleaners.Afternoon?.length > 0) && (
                <MultiCleanerContainer
                  cleaners={multiDayCleaners}
                  numberOfCleaners={getEffectiveNumberOfCleaners()}
                  onSelectedCleanersChange={handleSelectedMultiCleanersChange}
                />
              )}
            </>
          )}

          <div className="checkbox-wrapper">
            <div className="checkbox-screen-container">
              <Checkbox 
                label="Weekly"
                checked={selectedServiceFrequency === 'weekly'}
                onChange={() => handleCheckboxChange('weekly')}
              />
            </div>
          </div>

          {selectedServiceFrequency === 'weekly' && (
            <>
              <LoadingIndicator isLoading={isLoading} text="Loading available days..." alignment="center" />
              <DaysContainer
                days={availableDays}
                onDayPress={handleDayPress}
                frequency="weekly" 
                isLoading={isLoading}
                areCleanersAvailable={areCleanersAvailable}
              />

              {soloDayCleaners.length > 0 && (
                <CleanersContainer
                  cleaners={soloDayCleaners}
                  numberOfCleaners={getEffectiveNumberOfCleaners()}
                  onSelectedCleanersChange={handleSelectedSoloCleanerChange}
                />
              )}

              {multiDayCleaners && (multiDayCleaners.Morning?.length > 0 || multiDayCleaners.Afternoon?.length > 0) && (
                <MultiCleanerContainer
                  cleaners={multiDayCleaners}
                  numberOfCleaners={getEffectiveNumberOfCleaners()}
                  onSelectedCleanersChange={handleSelectedMultiCleanersChange}
                />
              )}
            </>
          )}

          <ErrorBox isVisible={errors.length > 0} errorTexts={errors} width='unset' />
        </div>
      </div>

      {/* Fixed Bottom Bar for "Next" Button */}
      <div className="fixed-bottom-bar">

        <div className="button-bar">
          <button
            className="blue-button"
            onClick={handleSchedule}    
            disabled={availableDates.length === 0}        
          >
            Next
          </button>
        </div>
      </div>  



    </div>
  );

}

export default SchedulingScreen;
