
// src/components/PhoneLogin.js
import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import ErrorBox from './ErrorBox';

function PhoneLogin({ onVerificationSuccess }) {
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false); // State for sending code
  const [isVerifyingCode, setIsVerifyingCode] = useState(false); // State for verifying code
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [errors, setErrors] = useState([]); 

  const inputRef = useRef(null);
  const codeInputRef = useRef(null); // Ref for the verification code input

  const auth = getAuth();

  // Format phone number for display in verification label
  const formattedPhone = phone.replace(/\D/g, '').length === 10
    ? `(${phone.replace(/\D/g, '').slice(0, 3)}) - ${phone.replace(/\D/g, '').slice(3, 6)} - ${phone.replace(/\D/g, '').slice(6)}`
    : '';

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        'size': 'invisible',
        'callback': (response) => {
          console.log('reCAPTCHA resolved');
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired, please refresh page and try again.');
        },
      }
    );

    window.recaptchaVerifier.render()
      .then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
        console.log("Invisible reCAPTCHA rendered successfully with widget ID:", widgetId);
      })
      .catch((error) => {
        console.error("Error rendering reCAPTCHA:", error);
        setErrors(['Please refresh page and try again.']);
      });
  };

  useEffect(() => {
    setupRecaptcha();
  }, [auth]);

  useEffect(() => {
    // Focus the verification code input when isCodeSent is true
    if (isCodeSent && codeInputRef.current) {
      codeInputRef.current.focus();
    }
  }, [isCodeSent]);

  const sendVerificationCode = () => {
    setIsSendingCode(true); // Set loading state for sending code
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = `+1${phone.replace(/\D/g, '')}`;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        setIsCodeSent(true);
        console.log(`SMS sent to ${phoneNumber}`);
        setErrors([]); // Clear any previous errors
      })
      .catch((error) => {
        console.error('Error sending SMS:', error);
        setErrors([error.message || 'Failed to send verification code.']);
      })
      .finally(() => {
        setIsSendingCode(false); // Reset loading state
      });
  };

  const handleKeyDownPhone = (e) => {
    if (e.key === 'Enter' && phone.replace(/\D/g, '').length === 10) {
      e.preventDefault();
      sendVerificationCode();
    }
  };

  const handleKeyDownCode = (e) => {
    if (e.key === 'Enter' && verificationCode.length === 6) {
      e.preventDefault();
      verifyCode(e);
    }
  };

  const verifyCode = (e) => {
    e.preventDefault();
    if (!confirmationResult) return;

    setIsVerifyingCode(true); // Set loading state for verifying code
    confirmationResult.confirm(verificationCode)
      .then((result) => {
        console.log('User signed in successfully:', result.user);
        onVerificationSuccess();
        setErrors([]); // Clear errors on success
      })
      .catch((error) => {
        console.error('Error verifying code:', error);
        setErrors([error.message || 'Invalid verification code. Please try again.']);
      })
      .finally(() => {
        setIsVerifyingCode(false); // Reset loading state
      });
  };

  const resetPhoneLogin = () => {
    window.location.reload(); // Refresh the entire window
  };

  return (
    <div className="marginBottom10">
      {!isCodeSent ? (
        <div className='flexColumn'>
          <label htmlFor="phone-input" className="phone-input-label">
            <strong>Enter your phone number to login or create an account and get started:</strong>
          </label>

          <InputMask
            mask="(999) - 999 - 9999"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            onKeyDown={handleKeyDownPhone}
            placeholder="(123) - 456 - 7890"
            ref={inputRef}
          >
            {(inputProps) => <input {...inputProps} type="tel" className="phone-input" />}
          </InputMask>
          <div>
            <button
              className="blue-button"
              onClick={sendVerificationCode}
              disabled={phone.replace(/\D/g, '').length !== 10 || isSendingCode} // Disable during loading
            >
              {isSendingCode ? 'Sending...' : 'Get Code'} {/* Update text dynamically */}
            </button>
          </div>
          <div id="recaptcha-container"></div>
        </div>
      ) : (
        <form onSubmit={verifyCode}>
          <label className="form-label">
            Verification Code for {formattedPhone}:
          </label>
          <input 
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            onKeyDown={handleKeyDownCode} // Trigger submission on Enter
            placeholder="Enter verification code"
            className="phone-input"
            ref={codeInputRef} // Set ref to the verification code input
          />
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              type="submit"
              className="blue-button"
              disabled={isVerifyingCode || verificationCode.length !== 6} // Disable during loading or if code isn't complete
            >
              {isVerifyingCode ? 'Verifying...' : 'Verify Code'} {/* Update text dynamically */}
            </button>
            <button type="button" className="blue-button" onClick={resetPhoneLogin}>Reset</button>
          </div>
        </form>
      )}

      {/* Render the ErrorBox component if there are errors */}
      <ErrorBox errorTexts={errors} width="100%" />
    </div>
  );
}

export default PhoneLogin;





// // src/components/PhoneLogin.js
// import React, { useState, useEffect, useRef } from 'react';
// import InputMask from 'react-input-mask';
// import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import ErrorBox from './ErrorBox';

// function PhoneLogin({ onVerificationSuccess }) {
//   const [phone, setPhone] = useState('');
//   const [verificationCode, setVerificationCode] = useState('');
//   const [isCodeSent, setIsCodeSent] = useState(false);
//   const [confirmationResult, setConfirmationResult] = useState(null);
//   const [errors, setErrors] = useState([]); 

//   const [isSendingCode, setIsSendingCode] = useState(false);
//   const [isVerifyingCode, setIsVerifyingCode] = useState(false);

//   const inputRef = useRef(null);
//   const codeInputRef = useRef(null); // Ref for the verification code input

//   const auth = getAuth();

//   // Format phone number for display in verification label
//   const formattedPhone = phone.replace(/\D/g, '').length === 10
//     ? `(${phone.replace(/\D/g, '').slice(0, 3)}) - ${phone.replace(/\D/g, '').slice(3, 6)} - ${phone.replace(/\D/g, '').slice(6)}`
//     : '';

//   const setupRecaptcha = () => {
//     window.recaptchaVerifier = new RecaptchaVerifier(
//       auth,
//       'recaptcha-container',
//       {
//         'size': 'invisible',
//         'callback': (response) => {
//           console.log('reCAPTCHA resolved');
//         },
//         'expired-callback': () => {
//           console.log('reCAPTCHA expired, please refresh page and try again.');
//         },
//       }
//     );

//     window.recaptchaVerifier.render()
//       .then((widgetId) => {
//         window.recaptchaWidgetId = widgetId;
//         console.log("Invisible reCAPTCHA rendered successfully with widget ID:", widgetId);
//       })
//       .catch((error) => {
//         console.error("Error rendering reCAPTCHA:", error);
//         setErrors(['Please refresh page and try again.']);
//       });
//   };


//   useEffect(() => {
//     setupRecaptcha();
//   }, [auth]);


//   useEffect(() => {
//     // Focus the verification code input when isCodeSent is true
//     if (isCodeSent && codeInputRef.current) {
//       codeInputRef.current.focus();
//     }
//   }, [isCodeSent]);




//   const sendVerificationCode = () => {
//     setIsSendingCode(true); // Set loading state
//     const appVerifier = window.recaptchaVerifier;
//     const phoneNumber = `+1${phone.replace(/\D/g, '')}`;

//     signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//       .then((result) => {
//         setConfirmationResult(result);
//         setIsCodeSent(true);
//         console.log(`SMS sent to ${phoneNumber}`);
//         setErrors([]); // Clear any previous errors
//       })
//       .catch((error) => {
//         console.error('Error sending SMS:', error);
//         setErrors([error.message || 'Failed to send verification code.']);
//       })
//       .finally(() => {
//         setIsSendingCode(false); // Reset loading state
//       });
//   };


//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter' && phone.replace(/\D/g, '').length === 10) {
//       e.preventDefault();
//       sendVerificationCode();
//     }
//   };

//   const verifyCode = (e) => {
//     e.preventDefault();
//     if (!confirmationResult) return;

//     confirmationResult.confirm(verificationCode)
//       .then((result) => {
//         console.log('User signed in successfully:', result.user);
//         onVerificationSuccess();
//         setErrors([]); // Clear errors on success
//       })
//       .catch((error) => {
//         console.error('Error verifying code:', error);
//         setErrors([error.message || 'Invalid verification code. Please try again.']);
//       });
//   };

//   const resetPhoneLogin = () => {
//     window.location.reload(); // Refresh the entire window
//   };

//   return (
//     <div className="marginBottom10">
//       {!isCodeSent ? (
//         <div className='flexColumn'>

//           <label htmlFor="phone-input" className="phone-input-label">
//             <strong>Enter your phone number to login or create an account and get started:</strong>
//           </label>

//           <InputMask
//             mask="(999) - 999 - 9999"
//             value={phone}
//             onChange={(e) => setPhone(e.target.value)}
//             onKeyDown={handleKeyDown}
//             placeholder="(123) - 456 - 7890"
//             ref={inputRef}
//           >
//             {(inputProps) => <input {...inputProps} type="tel" className="phone-input" />}
//           </InputMask>

//           <div>
//             <button
//               className="blue-button"
//               onClick={sendVerificationCode}
//               disabled={phone.replace(/\D/g, '').length !== 10 || isSendingCode} // Disable during loading
//             >
//               {isSendingCode ? 'Sending...' : 'Get Code'} {/* Update text dynamically */}
//             </button>
//           </div>


//           <div id="recaptcha-container"></div>
//         </div>
//       ) : (
//         <form onSubmit={verifyCode}>
//           <label className="form-label">
//             Verification Code for {formattedPhone}:
//           </label>
//           <input 
//             type="text"
//             value={verificationCode}
//             onChange={(e) => setVerificationCode(e.target.value)}
//             placeholder="Enter verification code"
//             className="phone-input"
//             ref={codeInputRef} // Set ref to the verification code input
//           />
//           <div style={{ display: 'flex', gap: '10px' }}>
//             <button type="submit" className="blue-button">Verify Code</button>
//             <button type="button" className="blue-button" onClick={resetPhoneLogin}>Reset</button>
//           </div>
//         </form>
//       )}

//       {/* Render the ErrorBox component if there are errors */}
//       <ErrorBox errorTexts={errors} width="100%" />


//     </div>
//   );
// }

// export default PhoneLogin;
