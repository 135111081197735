// src/components/ImageRow.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ImageRow.css';

// Image paths
const CUSTOMER_ICON = '/images/static_UI/customer_icon.png';
const ADD_TASK_ICON = '/images/static_UI/checklist_add.png';
const CHECKLIST_ICON = '/images/static_UI/check-list-v2.png';
const IMAGE_ICON = '/images/static_UI/image_icon.png';
const TEXT_ICON = '/images/static_UI/check-list-icon.png';
const CHECKED_IMAGE = '/images/static_UI/checked.png';
const UNCHECKED_IMAGE = '/images/static_UI/gray_unchecked.png';

const BATHROOM_ICON = '/images/static_UI/rooms/bathroom.png';
const BEDROOM_ICON = '/images/static_UI/rooms/bedroom.png';
const KITCHEN_ICON = '/images/static_UI/rooms/kitchen.png';
const GENERAL_ICON = '/images/static_UI/rooms/general.png';

const ImageRow = ({
  items,
  checklistItems = null,
  targetScreen,
  isNavigationEnabled = true,
  userType = 'customer',
  checkboxStatus = null,
  placeholderImageType = null,
  borderActive = false,
  routeParams = null,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (isNavigationEnabled && targetScreen) {
      navigate(targetScreen, { state: routeParams });
    }
  };

  const getPlaceholderImage = () => {
    switch (placeholderImageType) {
      case 'checklist':
        return CHECKLIST_ICON;
      case 'cleanerBeforeAfter':
        return IMAGE_ICON;
      case 'customerTasks':
        return ADD_TASK_ICON;
      case 'customerIcon':
        return CUSTOMER_ICON;
      default:
        return null;
    }
  };

  const renderPlaceholderImage = () => {
    const placeholderImage = getPlaceholderImage();
    return placeholderImage ? (
      <div className="image-row-image-wrapper"> {/* Add wrapper */}
        <img src={placeholderImage} alt="Placeholder" className="image-row-placeholder-image" />
      </div>
    ) : null;
  };

  const renderImageContent = () => {
    return items.map((item, index) => (
      <div key={index} className="image-row-image-wrapper">
        {item.image ? (
          <img src={item.image} alt={`Item ${index + 1}`} className="image-row-image" />
        ) : (
          <img src={TEXT_ICON} alt="Text Icon" className="image-row-image" />
        )}
        <div className="image-row-number-background">
          <span className="image-row-number">{index + 1}</span>
        </div>
      </div>
    ));
  };

  const getCheckboxImage = () => {
    switch (checkboxStatus) {
      case 'checked':
        return CHECKED_IMAGE;
      case 'unchecked':
        return UNCHECKED_IMAGE;
      default:
        return null;
    }
  };

  const renderChecklistRoomContent = () => {
    return checklistItems.map((item, index) => {
      let imageSource;
      switch (item.type) {
        case 'bathroom':
          imageSource = item.image || BATHROOM_ICON;
          break;
        case 'bedroom':
          imageSource = item.image || BEDROOM_ICON;
          break;
        case 'kitchen':
          imageSource = item.image || KITCHEN_ICON;
          break;
        case 'general':
          imageSource = item.image || GENERAL_ICON;
          break;
        default:
          imageSource = item.image || GENERAL_ICON;
      }

      return (
        <div key={index} className="image-row-image-wrapper">
          <img src={imageSource} alt={`${item.type} Icon`} className="image-row-image" />
          <div className="image-row-number-background">
            <span className="image-row-number">{index + 1}</span>
          </div>
        </div>
      );
    });
  };

  const renderContent = () => {
    if (!items && checklistItems && checklistItems.length > 0) {
      return renderChecklistRoomContent();
    } else if (items && items.length > 0) {
      return renderImageContent();
    } else {
      let message = '';
      if (userType === 'cleaner') {
        message = 'No images added';
      } else if (userType === 'customer') {
        message = 'No customer tasks';
      } else {
        message = 'No tasks added';
      }

      return <span className="image-row-note-text">{message}</span>;
    }
  };

  return (
    <div
      onClick={handleNavigate}
      className={`image-row-container ${borderActive ? 'image-row-border-active' : ''}`}
      role="button"
    >
      <div className="image-row-content-wrapper">
        {renderPlaceholderImage()}
        <div className="image-row-grid">{renderContent()}</div>
        {checkboxStatus && (
          <div className="image-row-checkbox-container">
            <img src={getCheckboxImage()} alt="Checkbox" className="image-row-checkbox-image" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageRow;
