// src/components/ToDoCard.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, ref as storageRef, uploadBytesResumable } from 'firebase/storage';

import ImageModal from './ImageModal';
import UploadProgress from './UploadProgress';
import { updateTaskText } from '../slices/tasksSlice';
import { app } from '../firebaseConfig';


function ToDoCard({ number, toDoImage, toDoText, handleDelete, taskId }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const propertyId = useSelector((state) => state.property.id);
  const userId = useSelector((state) => state.user.id);
  const jobId = useSelector((state) => state.job.id);

  // Open and close modal
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // Handle text area changes, specifically updating taskCustomerText
  const handleTextChange = (event) => {
    const newText = event.target.value;
    console.log(`Updating task ${taskId} with text:`, newText);
    dispatch(updateTaskText({ id: taskId, taskCustomerText: newText }));
  };

  // Handle file selection and upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      alert("Invalid file type. Please upload an image in JPEG, PNG, or WebP format.");
      return;
    }

    const filename = `${taskId}_${file.name}`;
    const storage = getStorage(app, 'gs://sparkle_images_temp');
    const fileRef = storageRef(storage, filename);

    const metadata = {
      customMetadata: {
        taskId: String(taskId),
        beforeAfterId: '',
        jobId: String(jobId),
        propertyId: String(propertyId),
        userId: String(userId),
        photoType: 'before'
      }
    };    

    const uploadTask = uploadBytesResumable(fileRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
        setUploadStatus(snapshot.state);
      },
      (error) => {
        console.error('Upload failed', error);
        setUploadStatus('error');
      },
      async () => {
        console.log('Uploaded successfully');
        setUploadStatus('uploaded');
      }
    );
  };

  return (
    <div className="todo-card">
      <div className="todo-header-row">Task #{number}</div>

      <div className="todo-image-container" onClick={toDoImage?.beforeThumbURL ? openModal : null}>
        {toDoImage?.beforeThumbURL ? (
          <img
            src={toDoImage.beforeThumbURL}
            alt={`Task ${number} image`}
            className="todo-image"
          />
        ) : (
          <div className="todo-no-image-message">No task image uploaded</div>
        )}
      </div>

      {isLoggedIn && (
        <div className="todo-delete-container">
          <button className="todo-delete-button" onClick={handleDelete}>
            <img src="/images/static_ui/delete-icon.png" alt="Delete" style={{ width: '35px', height: '35px' }} />
          </button>
        </div>
      )}

      <div className="todo-description-header">Task Description</div>
      <textarea
        className="todo-text-area"
        value={toDoText}
        onChange={handleTextChange}
        placeholder='Enter task description text...'
      />

      <div className="todo-upload-container">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="upload-input"
        />
        <UploadProgress 
          isUploading={uploadProgress > 0 && uploadProgress < 100}
          progressPercent={uploadProgress}
        />
      </div>

      {isModalOpen && (
        <ImageModal
          images={[{ largeUrl: toDoImage?.beforeOriginalURL }]}
          currentIndex={0}
          onClose={closeModal}
          onNext={() => {}}
          onPrevious={() => {}}
        />
      )}
    </div>
  );
}

export default ToDoCard;