import React from 'react';
import './LaborHoursDisplay.css'; // Ensure this CSS file is linked correctly

const LaborHoursDisplay = ({ laborDetails }) => {
  if (!laborDetails || laborDetails.hours <= 0) return null;

  const totalHours = laborDetails.hours;
  const hours = Math.floor(totalHours); // Get whole hours
  let minutes = Math.ceil((totalHours - hours) * 60); // Get remaining minutes and round to next whole number

  // Round minutes up to the next 5-minute interval
  minutes = Math.ceil(minutes / 5) * 5;

  // Format the display text
  const displayTime = () => {
    if (hours > 0 && minutes > 0) {
      return `${hours} hours, ${minutes} minutes`;
    } else if (hours > 0) {
      return `${hours} hours`;
    } else {
      return `${minutes} minutes`;
    }
  };

  return (
    <div className="labor-hours-container">
      <span className="hours-title">{laborDetails.hoursTitle}:</span>
      <span className="hours-value">{displayTime()}</span>
    </div>
  );
};

export default LaborHoursDisplay;