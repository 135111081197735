// src/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { setUser, clearUser } from './slices/authSlice';
import { updateUser } from './slices/userSlice';
import { auth } from './firebaseConfig';
import { getFirebaseToken } from './utils/firebaseTokenUtil';
import axios from 'axios';
import { apiBaseUrl } from './apiConfig';

import HomeScreen from './screens/HomeScreen';
import MarketingScreen from './screens/MarketingScreen';
import EmailLoginScreen from './screens/EmailLoginScreen';
import ServiceHome from './screens/ServiceHome';
import DefineJobScreen from './screens/DefineJobScreen';
import PropertyScreen from './screens/PropertyScreen';
import ToDoListScreen from './screens/ToDoListScreen';
import SchedulingScreen from './screens/SchedulingScreen';
import PaymentScreen from './screens/PaymentScreen';
import SummaryScreen from './screens/SummaryScreen';

import PhoneLoginScreen from './screens/PhoneLoginScreen';
import CompleteSignupScreen from './screens/CompleteSignupScreen';

import './styles.css';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);



  // // Function to fetch user data using the Firebase token
  // const fetchUserData = async () => {
  //   try {
  //     const firebaseIdToken = await getFirebaseToken();
  //     if (firebaseIdToken) {
  //       const response = await axios.post(`${apiBaseUrl}/fetch-user-data`, {}, {
  //         headers: { Authorization: `Bearer ${firebaseIdToken}` },
  //       });

  //       if (response.status === 200 && response.data) {
  //         dispatch(updateUser(response.data)); // Update user in Redux
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user data:', error);
  //   }
  // };

  // // Initialize auth state on app startup
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
  //     if (currentUser) {
  //       dispatch(setUser());
  //       await fetchUserData();
  //     } else {
  //       dispatch(clearUser());
  //     }
  //     setIsLoading(false);
  //   });

  //   return () => unsubscribe();
  // }, [dispatch]);


  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
  //     if (currentUser) {
  //       try {
  //         await fetchUserData(); // Fetch user data before setting login state
  //         dispatch(setUser()); // Only mark as logged in after fetching user data
  //       } catch (error) {
  //         console.error('Error fetching user data:', error);
  //       }
  //     } else {
  //       dispatch(clearUser());
  //     }
  //     setIsLoading(false);
  //   });
  
  //   return () => unsubscribe();
  // }, [dispatch]);

  
  // const fetchUserData = async () => {
  //   try {
  //     const firebaseIdToken = await getFirebaseToken();
  //     if (firebaseIdToken) {
  //       const response = await axios.post(`${apiBaseUrl}/fetch-user-data`, {}, {
  //         headers: { Authorization: `Bearer ${firebaseIdToken}` },
  //       });
  
  //       if (response.status === 200 && response.data) {
  //         dispatch(updateUser(response.data)); // Update user data in Redux
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user data:', error);
  //     throw error; // Throw error to handle it in the caller
  //   }
  // };
  


  // Function to handle Firebase sign-in
  const handleFirebaseSignIn = async () => {
    try {
      const firebaseIdToken = await getFirebaseToken(); // Fetch the Firebase ID token
      const phoneNumber = auth.currentUser?.phoneNumber || null; // Get the phone number from the current Firebase user

      if (firebaseIdToken) {
        // Call the new /firebase-sign-in route
        const response = await axios.post(`${apiBaseUrl}/firebase-sign-in`, {
          idToken: firebaseIdToken,
          phoneNumber: phoneNumber,
        });

        if (response.status === 200 && response.data) {
          dispatch(updateUser(response.data)); // Update the user state with the received data
        }
      }
    } catch (error) {
      console.error('Error during Firebase sign-in:', error);
      throw error; // Re-throw the error to handle it in the caller
    }
  };


  // Initialize auth state on app startup
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          await handleFirebaseSignIn(); // Use the new /firebase-sign-in route
          dispatch(setUser()); // Only mark as logged in after signing in
        } catch (error) {
          console.error('Error handling Firebase sign-in:', error);
        }
      } else {
        dispatch(clearUser());
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);


  const needsSignupCompletion = isLoggedIn && (!user.firstName?.trim() || !user.lastName?.trim());

  // useEffect for login redirection logic
  useEffect(() => {
    if (isLoggedIn) {
      if (needsSignupCompletion) {
        navigate("/complete-signup");
      } else {
        navigate("/servicehome");
      }
    }
  }, [isLoggedIn, needsSignupCompletion]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {/* Unrestricted access to the home route */}
      <Route path="/" element={<HomeScreen />} />
      
      <Route path="/marketing" element={isLoggedIn ? <MarketingScreen /> : <Navigate to="/login" />} />
      <Route path="/login" element={isLoggedIn ? <Navigate to="/marketing" /> : <EmailLoginScreen />} />
      <Route path="/servicehome" element={isLoggedIn ? <ServiceHome /> : <Navigate to="/" />} />
      <Route path="/definejob" element={isLoggedIn ? <DefineJobScreen /> : <Navigate to="/" />} />
      <Route path="/property" element={isLoggedIn ? <PropertyScreen /> : <Navigate to="/" />} />
      <Route path="/todo-list" element={isLoggedIn ? <ToDoListScreen /> : <Navigate to="/" />} />
      <Route path="/scheduling" element={isLoggedIn ? <SchedulingScreen /> : <Navigate to="/" />} />
      <Route path="/payment" element={isLoggedIn ? <PaymentScreen /> : <Navigate to="/" />} />
      <Route path="/summary" element={isLoggedIn ? <SummaryScreen /> : <Navigate to="/" />} />

      {/* Block access to /complete-signup if user doesn't need signup completion */}
      <Route path="/complete-signup" element={needsSignupCompletion ? <CompleteSignupScreen /> : <Navigate to="/servicehome" />} />

      {/* Unprotected route for testing PhoneLogin */}
      <Route path="/test-phone-login" element={<PhoneLoginScreen />} />
    </Routes>
  );
}

export default App;



